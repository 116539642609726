import React from "react"
import SEO from "../components/seo"
import Header from "../components/header"
import video from "../videos/Video-Home-01.mp4"
import videoTablet from "../videos/Video-Home-02.mp4"
import Footer from "../components/Footer"
import HomeVideo from "../components/HomeVideo"
import Menu from "../components/Menu"
import videoMobile from "../videos/Video-Home-03.mp4"
import MenuMobile from "../components/MenuMobile"
import Cookies from "js-cookie";
import BirthConsent from "../components/BirthConsent"
import ModalCovid from './../components/ModalCovid';
import { graphql, StaticQuery } from "gatsby"


const breakpoints = {
  mobile: 320,
  mobileLandscape: 480,
  tablet: 768,
  tabletLandscape: 1024,
  desktop: 1200,
  desktopLarge: 1500,
  desktopWide: 1920,
}
const guessedBreakpoint = breakpoints.desktop

const hasConfirmed = () => {
  return (Cookies.get('patagoniaBirthConsent') !== undefined && Cookies.get('patagoniaBirthConsent'))
    || (localStorage.getItem('patagoniaBirthConsent') !== undefined && localStorage.getItem('patagoniaBirthConsent'));
};


class Index extends React.Component {
    timeout = null;
    constructor(props) {
      super(props)
      this.state = {
        isScrolling: false,
        menuOpen: false,
        menuClass: 'slideOut',
        isDark: false,
        width: 1000,
        birthConsent: true,
      }
    }

    componentDidMount() {
      window.addEventListener("scroll", this.onScroll);
      this.setState({
        width: window.innerWidth,
      })


      if (hasConfirmed()){

        this.handleConsent()
      }
      else {
        this.setState({
          birthConsent: false,
        })
      }
    }




    handleConsent = () =>{
      this.setState({
        birthConsent: true,
      })
    }

    componentWillUnmount() {
      window.removeEventListener("scroll", this.onScroll);
    }

    onMenuToggle = () =>{
      this.setState({
        menuOpen: !this.state.menuOpen,
        menuClass: this.state.menuOpen ? 'slideOut' : 'slideIn',
        isDark: !this.state.menuOpen
      })
    };

    onScroll = () => {
      this.setState({ isScrolling: true });

      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.setState({ isScrolling: false });
      }, 200);
    };
    render() {

      return (
        <div>
          <SEO title="Home" />

          {this.state.birthConsent &&
          <div>
            <ModalCovid dataGraph={this.props.data.allPopup.edges[0].node} />
            <Header isScrolling={this.state.isScrolling} onMenuToggle={this.onMenuToggle} isDark={this.state.menuOpen}
                    menuOpen={this.state.menuOpen} width={this.state.width}/>
            <HomeVideo width={this.state.width}/>
            {
              this.state.width > 767 ?
                <Menu menuClass={this.state.menuClass} menuOpen={this.state.menuOpen}/>
                :
                <MenuMobile onMenuToggle={this.onMenuToggle} menuClass={this.state.menuClass} menuOpen={this.state.menuOpen}/>
            }
            <Footer/>
          </div>
          }
          {!this.state.birthConsent &&
            <BirthConsent handleConsent={this.handleConsent}/>
          }

          
        </div>

      )
    }
    
}
export default () => (
  <StaticQuery
    query={graphql`
      query QueryPopUp {
        allPopup {
          edges {
            node {
              button
              firstText
              imagen
              imageMobile
              isOn
              lastText
              link
              title
            }
          }
        }
      }      
    `}
    render={data => (
      <Index data={data}/>
    )}
  />
)