import React, { useEffect, useState } from "react"
import styles from "./../styles/modal-covid.module.scss"
import close from "../images/close.png"

export default function ModalCovid({dataGraph}) {
    const [open, setOpen] = useState(true)

    const [windowWidth, setWindowWidth] = useState(0)

    useEffect(()=> {
        setWindowWidth(window.innerWidth)
    },[])
    const handleClose = () => {
        setOpen(false)
    }

    const openButton = () => {
        if (dataGraph.link.includes('http')){
            window.open(dataGraph.link, "_blank")
        }
        else {
            window.open('https://' + dataGraph.link, "_blank")
        }
    }

    return (
        dataGraph.isOn && open ? (
            <div className={styles.containerModal}>
                {/* <Modal open={open} onClose={handleClose}> */}
                    <div
                        style={{ backgroundImage: windowWidth > 767 ?`url(${dataGraph.imagen})` : `url(${dataGraph.imageMobile})`  }}
                        className={styles.modalBG}
                    >
                        <div  className={styles.modalContainer}>
                            <a onClick={handleClose} className={styles.iconContainer}>
                                {/* <CloseIcon className={styles.iconClose} /> */}
                                <img src={close}/>
                            </a>
                            <h1 className={styles.title}>{dataGraph.title}</h1>
                            <h2 className={styles.firstText}>{dataGraph.firstText}</h2>
                            {
                                dataGraph.button &&
                                    <div className={styles.craftContainer}>
                                        <button
                                            onClick={openButton}
                                            className="reservationButton3"
                                        >
                                            {dataGraph.button}
                                        </button>
                                    </div>
                            }

                            <h2 className={styles.lastText}>{dataGraph.lastText}</h2>
                        </div>
                    </div>
                {/* </Modal> */}
            </div>
        ) : null
    )
}