import React, { useEffect } from "react"
import videoTablet from "../videos/Video-Home-02.mp4"
import videoMobile from "../videos/Video-Home-03.mp4"
import video from "../videos/Video-Home-01.mp4"

function HomeVideo({ width }) {
  useEffect(() => {
    /*let bgvideo = document.getElementById("video");
    bgvideo.muted = true;
    bgvideo.play();*/
  }, [])
  return (
    <div>
      {width > 1200 && (
        <video
          id={"video"}
          className={"homeVideo slide-in-left"}
          playsInline
          loop
          muted
          autoPlay
          disablePictureInPicture="true"
        >
          <source src={video} />
        </video>
      )}
      {width > 768 && width <= 1200 && (
        <video
          id={"video"}
          className={"homeVideo slide-in-left"}
          playsInline
          loop
          muted
          autoPlay
          disablePictureInPicture="true"
        >
          <source src={videoTablet} />
        </video>
      )}
      {width <= 768 && (
        <video
          id={"video"}
          className={"homeVideo slide-in-left"}
          playsInline
          loop
          muted
          autoPlay
          disablePictureInPicture="true"
        >
          <source src={videoMobile} />
        </video>
      )}
    </div>
  )
}

export default HomeVideo
